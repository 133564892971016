@keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    display: block;
    opacity: 1;
    transform: translate3d(0, -30px, 0);
  }
}

.fadeText {
  opacity: 0;
  animation: fadeIn 0.6s ease-in both;
}

.faded {
  opacity: 0;
  user-select: none;
}

@media only screen and (max-width: 992px) {
  .faded {
    display: none;
  }

  .descriptionText {
    font-size: small !important;
  }

  .titleText p {
    font-size: medium !important;
  }
}
@media only screen and (min-width: 992px) {
  .titleText p {
    font-size: 1vw !important;
  }
  .descriptionText {
    font-size: 0.6vw !important;
  }
}

.buttonTextCenter span {
  position: absolute;
  top: 50%;
  margin: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonTextCenter {
  position: relative;
}

.expandtext {
  background-color: #0c0c0c;
  padding-top: 30px;
  z-index: -1;
  transform: translateY(-30px) !important;
  width: 99.8%;
}

.TextExpand {
  width: 100%;
  background-image: url("../Images/TextBackground.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.TextExpand p {
  user-select: none;
  margin: 0;
  padding: 0;
  line-height: 0.9;
  font-size: 16px;
  font-weight: 500;
}
