@media only screen and (min-width: 1200px) {
  .mainDiv {
    position: absolute;
    transform: translate(-50%, -20%);
    top: 50%;
    left: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .logoImageDiv {
    position: absolute;
    bottom: 2vw;
  }
}
@media only screen and (max-width: 1200px) {
  .logoImageDiv {
    margin-bottom: 2vw;
  }
}

@media only screen and (min-width: 992px) {
  .logoImage {
    width: 2vw;
  }
}
@media only screen and (max-width: 992px) {
  .logoImage {
    width: 6vw;
  }
}
