.audio canvas {
  height: 300px !important;
  width: 100% !important;
}

.sticky-bottom {
  z-index: -10;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.playButtonAudio:focus {
  outline: none !important;
  box-shadow: none !important;
}

.volume {
  -webkit-appearance: none;
  height: 30px;
  background: #4a4a4a;
  outline: none;
  border-radius: 0px;
}
.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  background: #8ef6be;
  cursor: pointer;
}
.volume::-moz-range-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  background: #8ef6be;
  cursor: pointer;
}

/* isMobile */
@media only screen and (max-width: 992px) {
  .volText {
    font-size: 3vw !important;
  }

  .volume {
    width: 30%;
  }
}

/* !isMobile */
@media only screen and (min-width: 992px) {
  .volText {
    font-size: 1vw !important;
  }
}

@media only screen and (max-width: 2500px) {
  .volume::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
  }
  .volume::-moz-range-thumb {
    width: 10px;
    height: 10px;
  }

  .volume {
    height: 10px;
  }
  .playButtonImage {
    width: 30%;
    height: 30%;
  }
}

/* isHQ */
@media only screen and (min-width: 2500px) {
  .volume::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
  }
  .volume::-moz-range-thumb {
    width: 10px;
    height: 10px;
  }

  .volume {
    width: 70%;
    height: 10px;
  }
  .playButtonImage {
    width: 30%;
    height: 30%;
  }
}

@media screen and (max-width: 2500px) and (min-width: 992px) {
  .volume {
    width: 50%;
  }
}
