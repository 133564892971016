.rainbowBackground {
  background-image: url("../Images/RainbowBackground.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  border: 0;
  transition: 0.2s ease-in-out 0s;
}

.rainbowBackground:hover {
  transform: scale(1.25);
}

.rainbowBackground {
  font-weight: 900 !important;
}

.Congrats-modal {
  background-color: rgba(71, 71, 71, 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Overlay--after-open {
  background-color: #000000d6 !important;
}

.loadingContainer div {
  margin: auto !important;
}

@media only screen and (min-width: 992px) {
  .buttonTextCenter {
    width: 15%;
    height: 2.5vw;
  }
  .mintText {
    font-size: 1vw;
  }
}

@media only screen and (max-width: 992px) {
  .buttonTextCenter {
    width: 40%;
    height: 6vw;
  }
  .mintText {
    font-size: 3vw;
  }
}
